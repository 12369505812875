/**
HEADER
**/
.logo {
    max-width: 620px;
    pointer-events: none;
    margin: 0 auto 60px;
    .link {
        display: block;
    }
    img {
        width: 100%;
    }
}
nav {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 20;
    button {
        background: transparent;
        border: 0px;
        border-radius: 0px;
        color: #e3bd65;
        padding: 0px;
        cursor: pointer;
        font-size: 40px;
        transform: scaleY(0.7);
        position: relative;
        z-index: 10;
    }
    .navList {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;
        max-height: 0px;
        transition: all .2s ease-in-out;
        background-color: #282c34;
        padding-left: 10px;
        a {
            display: block;
            padding: 0px 0px;
            transition: all .2s ease-in-out;
            &.active {
                font-weight: bold;
            }
        }
    }
    &.open {
        .navList {
            opacity: 1;
            visibility: visible;
            max-height: 500px;
            a {
                padding: 10px 0px;
            }
        }
    }
}
@media (max-width: 768px) {
    nav {
        top: 10px;
        right: 15px;
    }
}