.homeBanners {
    .banner {
        display: block;
        padding: 30px 0;
        .bannerImage {
            cursor: pointer;
        }
        .bannerImage img {
            transform: scale(1);
            transition: all 0.2s ease-in-out;
            box-shadow: 0 0 0 0 rgba(0,0,0,0);
            cursor: pointer;
            &:hover {
                transform: scale(1.1);
                box-shadow: 6px 0 22px 21px rgba(0,0,0,0.2);
            }
        }      
        .bannerCaption {
            text-align: center;
            padding-top: 16px;
        }
    }
}
@media (max-width:768px) {
    .homeBanners {
        .banner {
            padding: 15px 0;
        }
    }
}