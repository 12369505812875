/**
VARIABLES - COLORS AND SPACING
**/
$primary: #e3bd65;
$text: #e3bd65;
$link: #e3bd65;
$head: #ffffff;
$background: #282c34;
$padding: 60px;
$font-head: 'Lora', serif;
$font-body: 'Poppins', sans-serif;

/**
GLOBAL
**/
* {
  box-sizing: border-box;
}
body {
  background-color: $background;
  color: $text;
  font-family: $font-body;
  font-size: 16px;
  margin: 0;
  font-weight: 300;
}
img {
  max-width: 100%;
}
a {
  color: $link;
  text-decoration: none;
  &:hover {
    font-weight: bold;
  }
}
h1, h2, h3 {
  font-family: $font-head;
  color: $head;
  font-weight: 300;
}
h1.large {
  font-size: 50px;
}
.link {
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: $padding;
}
.textCenter {
  text-align: center;
}
.width600 {
  max-width: 600px;
  margin: 0 auto;
}
.divider {
  max-width: 620px;
  width: 100%;
  height: 2px;
  background-color: $primary;
  margin: $padding auto;
}

@media (max-width: 768px) {
  .container {
    padding: 60px 30px 30px;
  }
  .divider {
    margin: 30px auto;
  }
}
