.Team {
  h1 {
    padding-bottom: 30px;
  }

  h2 {
    margin: 80px auto 48px;
    font-size: 48px;
  }
}
.team {
  line-height: 130%;
}
.team h2 {
  font-size: 30px;
}
.team h3 {
  text-align: center;
  text-transform: none;
  margin-bottom: 10px;
  margin-top: 0px;
  letter-spacing: 0px;
  font-size: 20px;
}
.team p {
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
}
.team .detail-img {
  width: 200px;
  height: 202px;
  margin: 0 auto;
  position: relative;
}
.team .detail-img img {
  width: 188px;
}
.team .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.team .row .col {
  min-width: 225px;
  margin-top: 30px;
  flex: none;
}
.team .team-socials {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.team .team-socials li {
  padding: 0px 6px;
  position: relative;
}
.secondary-bg {
  padding: 60px 0px;
}
.team .team-socials li .tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #100f21;
  text-align: center;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2px 10px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.team .team-socials li:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.team small {
  opacity: 0.6;
}
.team .detail-img .hiddenPFP {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.team .detail-img:hover .hiddenPFP {
  visibility: visible;
  opacity: 1;
}
